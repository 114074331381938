@import '../../styles/propertySets.css';
@import '../../styles/customMediaQueries.css';

.root {
}

.heroContainer {
  display: flex;
  flex-direction: column;
  background-color: var(--marketplaceColorUltraLight);
  padding: 48px 0;
  height: 70vh;

  background-image: url('./bg.jpg');
  background-position: bottom;

  @media (--viewportMedium) {
    height: 50vh;
  }

  @media (min-width: 1024px) {
    padding: 0;
  }

  @media (--viewportLargeWithPaddings) {
    height: 100vh;
    background-size: cover;
  }

  @media (min-width: 1434px) {
    background-color: var(--matterColorBright);
  }
}

.hero {
  flex-grow: 1;
  justify-content: flex-end;

  @media (--viewportMedium) {
    justify-content: center;
  }
}

.sections {
  @apply --marketplacePageWidth;

  @media (--viewportMedium) {
    padding-top: 48px;
  }
}

.section {
  position: relative;
}

.sectionContent {
  padding: 48px 0;
}

.sectionContentFirstChild {
  composes: sectionContent;
}

.sectionIntro {
  @media (--viewportMedium) {
    padding: 72px 0 96px;
  }
}

.sectionReviews {
  @media (--viewportMedium) {
    padding: 128px 0 48px;
  }
}

.sectionProfessionalFeatures {
  padding: 24px 0 24px;
}

.sectionProjects {
  padding-bottom: 96px;
}
